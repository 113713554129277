import React from 'react';
import { useField } from "formik"
import TextField from "@mui/material/TextField"

const FormikTextField = ({ statement }) => {
    const [field, meta, helpers] = useField(statement.name);
    let extraProps;

    if (statement.type === 'number') {
        extraProps = {
            min: statement.min,
            max: statement.max,
        };
    }

    if (statement.type === 'text') {
        extraProps = {
            multiline: statement.multiline,
            rows: statement.rows,
        };
    }

    const errorVisible = !!(meta.touched && meta.error);

    return (
        <TextField
            type={statement.type}
            label={statement.label}
            name={field.name}
            value={field.value}
            onChange={event => {
                helpers.setValue(event.target.value);
            }}
            helperText={errorVisible ? meta.error : null}
            error={errorVisible}
            {...extraProps}
        />
    );
};

export default FormikTextField;
