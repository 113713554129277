import React from 'react';
import { useField } from "formik"
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import StaticDatePicker from "@mui/lab/StaticDatePicker"
import TextField from "@mui/material/TextField"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import format from "date-fns/format";
import isSameDay from 'date-fns/isSameDay';
import isEqual from 'date-fns/isEqual';
import Box from "@mui/material/Box"
import FormHelperText from "@mui/material/FormHelperText"

const fromDateString = (dateString) => {
    const [year, month, day] = dateString.split('-');
    const date = new Date();

    date.setFullYear(+year);
    date.setMonth((+month) - 1);
    date.setDate(+day);

    return date;
};

const FormikCalendarSlotPicker = ({
    statement,
    params,
}) => {
    const [preferredTimeslotVisible, setSetPreferredTimeslotVisible] = React.useState(false);
    const [field, meta, helpers] = useField(statement.name);
    const [field2, meta2, helpers2] = useField(
        statement.customTimeslotFieldName,
    );
    const [,, helpers3] = useField(
        statement.preferredTimeslotVisibleFieldName,
    );

    const timeslotErrorVisible = !!(meta.touched && meta.error);
    const customTimeslotErrorVisible = !!(meta2.touched && meta2.error);

    const {
        start,
        end,
        interval = 60,
        slots,
        minDate = new Date(),
    } = params;

    const [
        calendarDate,
        setCurrentDate,
    ] = React.useState(() => {
        return fromDateString(Object.keys(slots)[0]);
    });

    const currentSlots = React.useMemo(() => {
        return slots[format(calendarDate, 'yyyy-MM-dd')] || [];
    }, [slots, calendarDate]);

    const shouldDisableDate = React.useCallback((day) => {
        const availableDates = Object.keys(slots)
            .map(fromDateString);

        return !availableDates.find(date => isSameDay(day, date));
    }, [slots]);

    React.useEffect(() => {
        return () => helpers3.setValue(false);
    }, []);

    return (
        <Box py={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {!preferredTimeslotVisible && (
                    <Stack direction="column">
                        <Stack spacing={1} direction="row">
                            <Box
                                sx={{
                                    '& .PrivatePickersSlideTransition-root': {
                                        minHeight: 230
                                    }
                                }}
                            >
                                <StaticDatePicker
                                    displayStaticWrapperAs="desktop"
                                    openTo="day"
                                    minDate={minDate}
                                    value={calendarDate}
                                    shouldDisableDate={shouldDisableDate}
                                    onChange={(newValue) => {
                                        // helpers.setValue(newValue);
                                        setCurrentDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Box>
                            <Stack sx={{ height: 300, py: 2, overflowY: 'scroll' }}>
                                {currentSlots.map(slot => {
                                    let styles = {
                                        borderRadius: 0,
                                    };

                                    if (isEqual(slot, field.value)) {
                                        styles = {
                                            ...styles,
                                            backgroundColor: 'primary.main',
                                            color: 'primary.contrastText',
                                        };
                                    }

                                    return (
                                        <Button
                                            sx={styles}
                                            key={format(slot, 'HH:mm')}
                                            disableRipple
                                            disableFocusRipple
                                            color="primary"
                                            variant="text"
                                            onClick={() => helpers.setValue(slot)}
                                        >
                                            {format(slot, 'HH:mm')}
                                        </Button>
                                    );
                                })}
                            </Stack>
                        </Stack>
                        <Button
                            onClick={() => {
                                helpers3.setValue(true);
                                setSetPreferredTimeslotVisible(true);
                            }}
                            sx={{alignSelf: 'center'}}
                            variant="text"
                            color="primary"
                            size="small">
                            I can't find a suitable time
                        </Button>
                        <FormHelperText error={timeslotErrorVisible}>
                            {timeslotErrorVisible && meta.error}
                        </FormHelperText>
                    </Stack>
                )}
                {preferredTimeslotVisible && (
                    <Stack spacing={1} direction="column">
                        <TextField
                            label={statement.customTimeslotLabel}
                            name={field2.name}
                            onChange={evt => {
                                helpers2.setValue(evt.target.value);
                            }}
                            value={field2.value}
                            multiline
                            rows={3}
                            helperText={customTimeslotErrorVisible ? meta2.error : null}
                            error={customTimeslotErrorVisible}
                        />
                        <Button
                            onClick={() => {
                                helpers3.setValue(false);
                                setSetPreferredTimeslotVisible(false);
                            }}
                            sx={{alignSelf: 'flex-start'}}
                            variant="text"
                            color="primary"
                            size="small">
                            Pick a time from the calendar
                        </Button>
                    </Stack>
                )}
            </LocalizationProvider>
        </Box>
    )
};

export default FormikCalendarSlotPicker;
