import * as yup from 'yup';
import { isPossiblePhoneNumber } from "react-phone-number-input"

const Field = ({
    type,
    defaultValue,
    validation,
    name,
    label,
    row,
    params,
    options,
    countryFieldName,
    defaultCountry = 'GB',
    placeholder,
    customTimeslotFieldName,
    preferredTimeslotVisibleName,
    customTimeslotLabel = 'Preferred date & time',
    customTimeslotRequiredAsterisk = false,
    preferredTimeslotVisibleFieldName,
    customTimeslotFieldValidation,
    async,
    getOptions,
    min = 0,
    max = Infinity,
    multiline,
    rows,
    inputFormat = ['datetime', 'date'].includes(type) ?  (
        type === 'datetime' ?
            'dd/MM/yyyy HH:mm' :
            'dd/MM/yyyy'
    ): undefined,
    effects = [],
    value,
    requiredAsterisk = false,
}) => {
    let result = {
        isField: true,
        name,
        label,
        type,
        row,
        defaultValue,
        validation,
        min,
        max,
        params,
        placeholder,
        effects,
    };

    switch (type) {
        case 'text':
            result = {
                ...result,
                multiline,
                rows,
            };
            break;
        case 'number':
        case 'address':
            break;
        case 'password':
            result = {
                ...result,
                password: true,
            };
            break;
        case 'tel':
            const lValidation = validation || yup.string();
            result = {
                ...result,
                countryFieldName,
                defaultCountry,
                validation: lValidation.test('testPhoneNumber', 'Phone number is invalid', (value) => {
                    return isPossiblePhoneNumber(value || '');
                }),
                defaultValue: defaultValue || '',
            };
            break;
        case 'radio':
        case 'checkbox':
            result = {
                ...result,
                options,
            };
            break;
        case 'select':
            result = {
                ...result,
                getOptions,
                options
            };
            break;
        case 'multiSelect':
            result = {
                ...result,
                async,
                getOptions,
                options,
                params,
            };
            break;
        case 'date':
        case 'datetime':
            result = {
                ...result,
                inputFormat,
            };
            break;
        case 'calendar-slot-picker':
            result = {
                ...result,
                customTimeslotFieldName,
                customTimeslotLabel,
                preferredTimeslotVisibleName,
                preferredTimeslotVisibleFieldName,
                customTimeslotFieldValidation,
                params,
            };

            if (customTimeslotRequiredAsterisk) {
                result.customTimeslotLabel = customTimeslotLabel + ' ' + '*';
            }
            break;
        case 'hidden':
            result = {
                ...result,
                value: value || [() => {}, []],
                hidden: true,
            };
            break;
        default:
            throw new Error(`Unsupported field type ${type}`);
    }

    if (validation?.exclusiveTests.required || requiredAsterisk) {
        result.label = result.label + ' ' + '*';
    }

    return result;
};

export default Field;
