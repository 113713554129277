import { MYACCOUNT_THEME } from "@myaccountUrls";

const getConversationalFormMetadata = (path) => {
    try {
        let projectData = require(`./forms/${MYACCOUNT_THEME}/${path}`).default;
        if (projectData) {
            return projectData;
        }
    } catch (err) {
    }

    return require(`./forms/default/${path}`).default;
};

export default getConversationalFormMetadata;
