import React from "react"
import PhoneInput from "react-phone-number-input"
import TextField from "@mui/material/TextField"
import { useField } from "formik"

const CustomPhoneNumber = React.forwardRef(({inputProps, ...props }, ref) => (
    <TextField inputRef={ref} {...inputProps} {...props} />
));

const FormikPhoneInput = ({ statement, defaultCountry }) => {
    const [field, meta, helpers] = useField(statement.name);
    const [field2, meta2, helpers2] = useField(statement.countryFieldName);
    const errorVisible = !!(meta.touched && meta.error);

    return (
        <div className="form-group phonenumber_container">
            <PhoneInput
                classes={{root: "globalForm-selectField"}}
                // defaultValue={formatPhoneNumberIntl(field.value)}
                international
                // countryCallingCodeEditable={false}
                defaultCountry={defaultCountry}
                placeholder={statement.placeholder || 'Enter phone number'}
                value={field.value}
                onChange={(newValue) => {
                    helpers.setValue(newValue || '');
                }}
                onCountryChange={(country) => {
                    helpers2.setValue(country || '');
                }}
                error={errorVisible}
                helperText={errorVisible ? meta.error : ""}
                label={statement.label}
                inputComponent={CustomPhoneNumber}
            />
        </div>
    )
};

export default FormikPhoneInput;
