import React from 'react';
import FormLabel from "@mui/material/FormLabel"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import FormGroup from "@mui/material/FormGroup"
import { useField } from "formik"

const FormikRadio = ({ statement }) => {
    const [field, meta, helpers] = useField(statement.name);

    return (
        <FormGroup className={`globalForm-fieldsRadio`}>
            <FormLabel htmlFor={statement.name}>
                {statement.label}
            </FormLabel>
            <RadioGroup
                onChange={evt => {
                    helpers.setValue(evt.target.value);
                }}
                value={field.value}
                row={statement.row}>
                {statement.options.map(option => (
                    <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                    />
                ))}
            </RadioGroup>
        </FormGroup>
    );
};

export default FormikRadio;
