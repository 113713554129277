export default {
    "props": {
        "defaultCountry": "GB",
        "textFieldProps": {
            // "variant": "outlined",
        },
        "radioGroupProps": {
            "row": true,
        },
        "selectFieldProps": {
            // "variant": "outlined",
        },
        "fieldsTitle": {
            "component": "h2",
            "variant": "h5",
        },
        "datetimeSlotProps": {
            "showBtnSuitableTime": true,
        },
        "formButton": {
            "variant": "contained",
            "color": "primary",
            // "fullWidth": true,
        },
        "_data": {
            "submitLabel": "Submit",
        }
    },
    "sx": (theme) => ({
        "& div:not(style)": {
            margin: "0 !important",
        },
        "& div[class$='MuiStack-root']": {
            margin: "0 !important",
            "& :not(style)+:not(style)" :{
                marginTop: "0 !important",
            },
        },
        "& .MuiFormControl-root": {
            "marginBottom": "1.5rem !important"
        },
        "& .globalForm-fieldsRadio": {
            "marginBottom": "1rem !important"
        },
        '& .multi-select-input': {
            "marginBottom": "1.5rem !important",
        },
        '& .multi-select__control.multi-select__control--menu-is-open': {
            "zIndex": "3",
            "&~.multi-select__menu": {
                "zIndex": "3",
            }
        },
        '& .multi-select-input-label': {
            "marginBottom": "10px"
        },
        "& .loqate-options": {
            // "zIndex": "9999"
            "padding": "2px",
            "maxHeight": "100px",
            "overflowX": "scroll"
        },
        "& .loqate-option": {
            "zIndex": "9999"
        },
        "& .globalForm-globalFormForm": {
        },
        "& .globalForm-fieldContainer": {
            "display": "flex",
            "flexDirection": "column",
        },
        "& .globalForm-textField": {
            "width": "100%",
            // "marginBottom": theme.spacing(3),
        },
        "& .globalForm-selectField": {
            "width": "100%",
            // "marginBottom": theme.spacing(3),
        },
        "& .myaccount-date-time-calendar": {
            "display": "flex",
            "flexDirection": "column",
            "alignItems": "center",
            "& .react-datepicker": {
                // "width": "100%",
                // "marginBottom": theme.spacing(3),
                "& .react-datepicker__month-container": {
                    "@media (max-width: 400px)": {
                        "width": "100%",
                    },
                },
                "& .react-datepicker__navigation--next": {
                    "@media (max-width: 400px)": {
                        "right": "2px",
                    },
                },
                "& .react-datepicker__time-container": {
                    "@media (max-width: 400px)": {
                        "width": "100%",
                        "border": "none",
                    },
                    "& .react-datepicker__header.react-datepicker__header--time": {
                        "@media (min-width: 401px)": {
                            "display": "flex",
                            "minHeight": "62px",
                            "alignItems": "center",
                            "justifyContent": "center",
                        },
                    },
                    "& .react-datepicker__time-box": {
                        "@media (max-width: 400px)": {
                            "width": "100%",
                        },
                    },
                    "& li.react-datepicker__time-list-item": {
                        "display": "flex",
                        "alignItems": "center",
                        "&.react-datepicker__time-list-item--disabled": {
                            "display": "none",
                        },
                    },
                    "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                        "backgroundColor": theme.palette.primary.main,
                    },
                    "& .react-datepicker__day--selected": {
                        "backgroundColor": theme.palette.primary.main,
                        "color": "#fff",
                        "&:hover": {
                            "backgroundColor": theme.palette.primary.main,
                        },
                    },
                },
            },
        },
        "& .suggestionsContainer": {
            "position": "relative",
            "& .suggestionsContainerOpen": {
                "position": "absolute",
                "zIndex": 998,
                "top": "50px",
                "left": 0,
                "right": 0,
            },
            "& .suggestionsList": {
                "margin": 0,
                "padding": 0,
                "listStyleType": "none",
            },
            "& .suggestion": {
                "display": "block",
                "padding": "10px 15px",
                "&:hover": {
                    "cursor": "pointer",
                    "background": theme.palette.grey.grey3,
                },
            },
        },
        // "& .globalForm-fieldsRadio": {
        //     // "marginBottom": theme.spacing(2),
        //     // "margin": 0,
        //     // "marginTop": theme.spacing(2),
        // },
        // "& .globalForm-actionContainer": {
        // },
        // "& .globalForm-fieldsTitle": {
        //     // "marginBottom": theme.spacing(3),
        // },
        // "& .globalForm-formButton": {

        // },
        "& .phonenumber_container": {
            // "background": 'red',
            "& .PhoneInputCountryIcon": {
                "width": "40px",
                "position": "absolute",
                "height": "40px",
                "& img,svg": {
                    "marginTop": "12px",
                    "paddingLeft": "10px",
                    "width": "100%"
                }
            },
            "& .PhoneInputCountrySelect": {
                "width": "40px",
                "position": "absolute",
                "opacity": "0",
                "zIndex": "2",
                "height": "40px",
                "cursor": "pointer",
            },
            "& .MuiFormControl-root": {
                "& input": {
                    "paddingLeft": "50px"
                }
            }
        },
    }),
}
