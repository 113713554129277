function Condition (condition, statement) {
    if (typeof condition !== 'function') {
        throw new Error('expect condition to be a function');
    }

    return {
        type: 'condition',
        if: condition,
        statement,
    };
}

export default Condition;
