import React from 'react';
import TextField from "@mui/material/TextField"
import DatePicker from "@mui/lab/DatePicker"
import { useField } from "formik"

const FormikDatePicker = ({ statement }) => {
    const [field, meta, helpers] = useField(statement.name);
    const errorVisible = !!(meta.touched && meta.error);

    return (
        <DatePicker
            classes={{root: "globalForm-textField"}}
            label={statement.label}
            inputFormat={statement.inputFormat}
            onChange={newValue => {
                helpers.setValue(newValue);
            }}
            value={field.value}
            renderInput={params => (
                <TextField
                    {...params}
                    classes={{root: "globalForm-textField"}}
                    helperText={errorVisible ? meta.error : null}
                    error={errorVisible}
                />
            )}
        />
    );
};

export default FormikDatePicker;
