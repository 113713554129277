const Block = ({ spacing = 2, direction = 'column' }, statement) => {
    if (typeof statement !== 'object') {
        throw new Error(`unexpected Block content ${statement}`);
    }

    if (!['row', 'column'].includes(direction)) {
        throw new Error(`unsupported Block direction ${direction}`);
    }

    return {
        type: 'block',
        spacing,
        direction,
        statement,
    };
};

export default Block;
