import DateTimePicker from "@mui/lab/DateTimePicker"
import TextField from "@mui/material/TextField"
import React from "react"
import { useField } from "formik"

const FormikDateTimePicker = ({ statement }) => {
    const [field, meta, helpers] = useField(statement.name);

    return (
        <DateTimePicker
            classes={{root: "globalForm-textField"}}
            label={statement.label}
            inputFormat={statement.inputFormat}
            onChange={newValue => {
                helpers.setValue(newValue);
            }}
            value={field.value}
            renderInput={params => <TextField {...params} />}
        />
    );
};

export default FormikDateTimePicker;
