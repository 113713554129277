import Form from "../../statetments/Form"
import Field from "../../statetments/Field"
import * as yup from "yup"
import Condition from "../../statetments/Condition"
import Block from "../../statetments/Block"
import { getAreas, getConfigValues } from "@myaccountServices/store/api"
import addDays from "date-fns/addDays"
import Typography from "../../statetments/Typography"
import LoadingIndicator from "../../statetments/LoadingIndicator"
import format from "date-fns/format"
import Effect from "../../statetments/Effect"
import { API_URL } from "@myaccountUrls";
import { authInstance } from "@myaccountServices/store/api"
import { bedrooms, minPrice, maxPrice, buildings } from './constants';

const VALUATION_URL = `${API_URL}/myaccount/getvaluationavailabilities`;

const findSelectedSlotInfo = (slotsMap, appointment_time) => {
    const slotsMapDateStrings = Object.keys(slotsMap);
    let selectedSlotInfo = null;

    const appointment_time_date = format(appointment_time, 'yyyy-MM-dd');
    const appointment_time_time = format(appointment_time, 'HH:mm');
    for (const dateString of slotsMapDateStrings) {
        if (appointment_time_date === dateString) {
            const availableSlotsInfos = slotsMap[dateString];

            selectedSlotInfo = availableSlotsInfos.find(slotInfo => {
                return slotInfo.start_time === appointment_time_time;
            });

            if (selectedSlotInfo) {
                break;
            }
        }
    }

    return selectedSlotInfo;
};

const registerPropertyPreference = Form({
    initialExtraState: {
      valuationResult: {
          status: 'idle',
          data: null,
          error: '',
      },
    },
}, [
    Field({
        type: 'text',
        name: 'profile_forename',
        label: 'First name',
        validation: yup.string().required('First name is required'),
    }),
    Field({
        type: 'text',
        name: 'profile_surname',
        label: 'Surname',
        validation: yup.string().required('Surname is required'),
    }),
    Field({
        type: 'tel',
        name: 'profile_mobile',
        label: 'Mobile',
        defaultCountry: 'profile_country',
        countryFieldName: 'profile_mobileCountry',
        Mobile: 'Mobile',
        defaultValue: '',
        validation: yup.string().required('Mobile is required'),
    }),
    Field({
        type: 'radio',
        name: 'profile_newsAndUpdatesSubscription',
        label: 'Agree to receive some interesting news and updates?',
        validation: yup.string()
            .required('Please choose one option'),
        row: true,
        options: [
            {label: 'Yes', value: true},
            {label: 'No', value: false},
        ],
    }),
    Field({
        type: 'radio',
        // defaultValue: 'buyer',
        name: 'profile_persona',
        label: 'I am a ...',
        validation: yup.string()
            .required('Please choose one option'),
        row: true,
        options: [
            {label: 'Seller', value: 'seller'},
            {label: 'Landlord', value: 'landlord'},
            {label: 'Buyer', value: 'buyer'},
            {label: 'Tenant', value: 'tenant'},
        ],
    }),
    Condition(values => {
        return ['buyer', 'tenant'].includes(values.profile_persona);
    }, Block({
        spacing: 1,
    }, [
        Field({
            type: 'address',
            name: 'profile_address',
            defaultValue: '',
            label: 'Current address',
            multiline: true,
            rows: 4,
            requiredAsterisk: false,
            // validation: yup.string().when('profile_persona', {
            //     is: value => ['buyer', 'tenant'].includes(value),
            //     then: schema => schema.required('Please pick an address'),
            // }),
        }),

        Condition(values => {
            return ['buyer'].includes(values.profile_persona);
        }, Block({
            spacing: 1,
        }, [
            Field({
                type: 'select',
                name: 'profile_sellingPosition',
                placeholder: 'Selling Position',
                label: "Selling Position",
                getOptions: async () => {
                    return [
                        { value: "nothingToSell-Nothing to Sell", label:"Nothing to Sell" },
                        { value: "renting-Renting", label:"Renting" },
                        { value: "sellingWithUs-Selling with us", label:"Selling with us" },
                        { value: "sellingWithOtherAgent-Selling with other Agent", label:"Selling with other Agent" },
                        { value: "sellingPrivately-Selling privately", label:"Selling privately" },
                        { value: "notYetOnMarket-Not yet on market", label:"Not yet on market" }
                    ]
                }
            }),
            Condition(values => {
                return !!(values?.profile_sellingPosition !== 'nothingToSell-Nothing to Sell' && values?.profile_sellingPosition && values?.profile_sellingPosition !== 'renting-Renting' && values?.profile_sellingPosition && values?.profile_sellingPosition !== 'notYetOnMarket-Not yet on market' && values?.profile_sellingPosition);
            }, Block({}, [
                Field({
                    type: 'select',
                    name: 'profile_sellingStatus',
                    placeholder: 'Selling Status',
                    label: "Selling Status",
                    getOptions: async () => {
                        return [
                            { value: "preAppraisal-Pre Appraisal", label:"Pre Appraisal" },
                            { value: "valuation-Valuation", label:"Valuation" },
                            { value: "paidValuation-Paid valuation", label:"Paid valuation" },
                            { value: "forSale-For Sale", label:"For Sale" },
                            { value: "forSaleUnavailable-For Sale unavailable", label:"For Sale unavailable" },
                            { value: "underOffer-Under offer", label:"Under offer" },
                            { value: "underOfferUnavailable-Under offer unavailable", label:"Under offer unavailable" },
                            { value: "reserved-Reserved", label:"Reserved" },
                            { value: "exchanged-Exchanged", label:"Exchanged" },
                            { value: "completed-Completed", label:"Completed" },
                            { value: "soldExternally-Sold externally", label:"Sold externally" },
                            { value: "withdrawn-Withdrawn", label:"Withdrawn" },
                        ]
                    }
                }),
            ])),
            Field({
                type: 'select',
                name: 'profile_buyingPosition',
                placeholder: 'Buying Position',
                label: "Buying Position",
                getOptions: async () => {
                    const response = await getConfigValues({
                        type: 'buyingPositions'
                    });
                    if (response.data.length) {
                        let options = [];
                        response.data.map((item) => {
                            let keys = Object.keys(item);
                            keys.map((k) => {
                                options.push({
                                    value: `${k}-${item[k]}`,
                                    label: item[k]
                                })
                            })
                        });
                        return options;
                    }

                    return [];
                }
            }),
            Field({
                type: 'select',
                name: 'profile_buyingReason',
                placeholder: 'Buying Reason',
                label: "Buying Reason",
                getOptions: async () => {
                    const response = await getConfigValues({
                        type: 'buyingReasons'
                    });
                    if (response.data.length) {
                        let options = [];
                        response.data.map((item) => {
                            let keys = Object.keys(item);
                            keys.map((k) => {
                                options.push({
                                    value: `${k}-${item[k]}`,
                                    label: item[k]
                                })
                            })
                        });
                        return options;
                    }

                    return [];
                }
            }),
        ])),

        Field({
            type: 'radio',
            name: 'createAlert',
            defaultValue: 'no',
            row: true,
            label: 'Would you like to create an alert for new properties?',
            validation: yup.string().required(),
            options: [
                {label: 'Yes', value: 'yes'},
                {label: 'No', value: 'no'},
            ],
        }),
        Condition(values => {
            return values.createAlert === 'yes';
        }, Block({}, [
            Field({
                type: 'multiSelect',
                name: 'preference_areas',
                placeholder: 'Start typing your area',
                async: true,
                getOptions: async () => {
                    const response = await getAreas();

                    if (response.data.length) {
                        return response.data;
                    }

                    return [];
                },
                params: {
                    clearable: true,
                    allowSelectAll: true,
                    closeMenuOnSelect: false,
                    hideSelectedOptions: false
                },
            }),
            Field({
                name: 'preference_bedroom',
                type: 'select',
                label: 'Bedrooms',
                requiredAsterisk: true,
                validation: yup.string()
                    .when(['profile_persona', 'createAlert'], {
                        is: (persona, createAlert) =>
                            ['buyer', 'tenant'].includes(persona) &&
                            createAlert === 'yes',
                        then: schema => schema.required('Bedroom number is required'),
                    }),
                options: bedrooms
                    ,
            }),
            Field({
                name: 'preference_minPrice',
                type: 'select',
                label: 'Min price',
                requiredAsterisk: true,
                validation: yup.string()
                    .when(['profile_persona', 'createAlert'], {
                        is: (persona, createAlert) =>
                            ['buyer', 'tenant'].includes(persona) &&
                            createAlert === 'yes',
                        then: schema => schema.required('Min price is required'),
                    }),
                options: values => {
                    if (values.profile_persona === 'buyer') {
                        return minPrice.sales
                    } else {
                        return minPrice.lettings;
                    }
                }
            }),
            Field({
                type: 'select',
                name: 'preference_maxPrice',
                label: 'Max Price',
                requiredAsterisk: true,
                defaultValue: '',
                validation: yup.string()
                    .when(['profile_persona', 'createAlert'], {
                        is: (persona, createAlert) =>
                            ['buyer', 'tenant'].includes(persona) &&
                            createAlert === 'yes',
                        then: schema => schema.required('Max price is required'),
                    })
                    .test('testMaxPrice', function (value) {
                        const minPrice = +this.parent.preference_minPrice;

                        if (!isNaN(value) && !isNaN(minPrice) && typeof (+value) === 'number' && typeof minPrice === 'number') {
                            return minPrice < +value ? true : this.createError({
                                path: this.path,
                                message: 'Max price should be above the minimal price',
                            });
                        }

                        return true;
                    }),
                options: values => {
                    if (values.profile_persona === 'buyer') {
                        return maxPrice.sales;
                    } else {
                        return maxPrice.lettings;
                    }
                },
            }),
            Field({
                type: 'select',
                name: 'preference_building',
                label: 'Property type',
                options: buildings?.residential || buildings,
                // params: {
                //     clearable: true,
                //     allowSelectAll: true,
                //     closeMenuOnSelect: true,
                //     hideSelectedOptions: false,
                // },
            }),
        ])),
    ])),
    Condition(values => {
        return ['seller', 'landlord'].includes(values.profile_persona);
    }, Block({}, [
        Field({
            type: 'radio',
            name: 'bookValuation',
            defaultValue: 'no',
            label: 'Would you like to book a valuation?',
            validation: yup.string().required(),
            row: true,
            options: [
                {label: 'Yes', value: 'yes'},
                {label: 'No', value: 'no'},
            ],
        }),
        Condition(values => {
            return (values.bookValuation === 'yes');
        }, Block({}, [
            Field({
                type: 'address',
                name: 'valuation_address',
                defaultValue: '',
                label: "What is your property address?",
                requiredAsterisk: true,
                validation: yup.string()
                    .when(['profile_persona', 'bookValuation'], {
                        is: (persona, bookValuation) => {
                          return (
                              ['landlord', 'seller'].includes(persona) && bookValuation === 'yes'
                          );
                        },
                        then: schema => schema.required('Please pick an address'),
                    }),
            }),
            Condition(values => !!values.valuation_address_rawAddress, [
                Effect({
                    effects: [
                        (values, extraState, setExtraState) => [
                            () => {
                                const asyncOp = async () => {
                                    const valuationPropertyType = values.profile_persona === 'seller' ? 'sales' : 'letting';
                                    if (values.valuation_address_postcode) {
                                        try {
                                            setExtraState({
                                                valuationResult: {
                                                    status: 'pending',
                                                },
                                            });

                                            let valuation_url = `${VALUATION_URL}/${valuationPropertyType}/${values.valuation_address_postcode}`
                                            let valuation_params = {};

                                            if (false) {
                                                valuation_params = {
                                                    type: valuationPropertyType,
                                                    postcode: values.valuation_address_postcode || '',
                                                }
                                            }

                                            const response = await authInstance.get(valuation_url, {
                                                params: valuation_params,
                                            });

                                            setExtraState({
                                                valuationResult: {
                                                    status: 'success',
                                                    data: response.data,
                                                    error: null,
                                                },
                                            });
                                        } catch (err) {
                                            setExtraState({
                                                valuationResult: {
                                                    status: 'error',
                                                    data: {},
                                                },
                                            });
                                        }
                                    }
                                };

                                asyncOp();
                            },
                            [values.profile_persona, values.valuation_address_postcode],
                        ],
                    ]
                }),
                Condition((values, extraState) => {
                    return extraState.valuationResult.status === 'success';
                }, Block({}, [
                    Field({
                        type: 'calendar-slot-picker',
                        name: 'appointment_time',
                        customTimeslotFieldName: 'valuation_message',
                        preferredTimeslotVisibleName: 'preferredTimeslotVisible',
                        preferredTimeslotVisibleFieldName: 'preferredTimeslotVisible',
                        customTimeslotRequiredAsterisk: true,
                        validation: yup.date().when(['profile_persona', 'bookValuation', 'preferredTimeslotVisible'], {
                            is: (profilePersona, bookValuation, preferredTimeslotVisible) =>
                                ['landlord', 'seller'].includes(profilePersona) &&
                                bookValuation === 'yes' && !preferredTimeslotVisible,
                            then: schema => schema.required('Please pick a slot'),
                        }),
                        customTimeslotFieldValidation: yup.string().when([
                            'profile_persona', 'bookValuation', 'preferredTimeslotVisible',
                        ], {
                            is: (profilePersona, bookValuation, preferredTimeslotVisible) =>
                                ['landlord', 'seller'].includes(profilePersona) &&
                                bookValuation === 'yes' && preferredTimeslotVisible,
                            then: schema => schema.required('Please pick a preferred time slot'),
                        }),
                        params: ({ extraState }) => {
                            let slots = [];

                            if (extraState.valuationResult.data) {
                                slots = Object.keys(extraState.valuationResult.data.slots)
                                    .reduce((acc, dateString) => {

                                        return {
                                            ...acc,
                                            [dateString]: extraState.valuationResult.data
                                                .slots[dateString]
                                                .map(slot => {
                                                    const [year, month, day] = dateString.split('-');
                                                    const [hours, minutes] = slot.start_time.split(':');

                                                    const slotDate = new Date();

                                                    slotDate.setDate(+day);
                                                    slotDate.setMonth(+month - 1);
                                                    slotDate.setFullYear(+year);
                                                    slotDate.setHours(+hours);
                                                    slotDate.setMinutes(+minutes, 0, 0);

                                                    return slotDate;
                                                }),
                                        };
                                    }, {});

                                return {
                                    minDate: addDays(new Date(), 1),
                                    maxDate: addDays(new Date(), 14),
                                    slots,
                                };
                            }

                            return {
                                minDate: addDays(new Date(), 1),
                                maxDate: addDays(new Date(), 14),
                                slots,
                            };
                        },
                    }),
                    Field({
                        type: 'hidden',
                        name: 'valuation_officeId',
                        defaultValue: '',
                        value: (values, extraState) => {
                            return extraState.valuationResult.data?.office_id;
                        },
                    }),
                    Field({
                        type: 'hidden',
                        name: 'valuation_negotiatorId',
                        defaultValue: '',
                        value: (values, extraState) => {
                            const slotsMap = extraState.valuationResult.data.slots;
                            const appointment_time = values.appointment_time;
                            if (slotsMap && appointment_time) {
                                const selectedSlotInfo = findSelectedSlotInfo(slotsMap, appointment_time);

                                if (selectedSlotInfo) {
                                    return selectedSlotInfo.negotiator_id;
                                }
                            }

                            return '';
                        },
                    }),
                    Field({
                        type: 'hidden',
                        name: 'valuation_duration',
                        defaultValue: 60,
                        value: (values, extraState) => {
                            return extraState.valuationResult.data?.duration || '';
                        },
                    }),
                    Condition((values) => {
                        return !values.preferredTimeslotVisible;
                    }, (
                        Field({
                            type: 'text',
                            name: 'valuation_message',
                            label: 'Message',
                            defaultValue: '',
                            multiline: true,
                            rows: 3,
                        })
                    )),
                ])),
                Condition((values, extraState) => {
                    return extraState.valuationResult.status === 'error';
                }, Block({}, [
                    Typography({
                        variant: 'subtitle',
                        component: 'h4',
                    }, 'Sorry, there were no appointments found for this postcode. To arrange your valuation, please submit your request and we will do our best to accomodate you'),
                    Field({
                        type: 'text',
                        name: 'valuation_message',
                        label: 'Preferred date & time',
                        requiredAsterisk: true,
                        defaultValue: '',
                        multiline: true,
                        rows: 3,
                        effects: [
                            (values, extraState, setExtraState, setFieldValue) => [
                                () => {
                                    setFieldValue('preferredTimeslotVisible', true);
                                },
                                [],
                            ]
                        ]
                    }),
                ])),
                Condition((values, extraState) => {
                    return extraState.valuationResult.status === 'pending';
                }, LoadingIndicator()),
            ])
        ])),
    ])),
]);

export default registerPropertyPreference;
