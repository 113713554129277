import getStatementField from "./getStatementField"

const getDefaultValueFallback = (type) => {
    switch (type) {
        case 'text':
        case 'number':
        case 'tel':
            return '';
        case 'multiSelect':
            return [];
        case 'radio':
            return '';
        default:
            return '';
    }
};


const getInitialValues = (form) => {
    let fieldsStatements = getStatementField(form.content);

    return fieldsStatements
        .filter(statement => statement.isField)
        .reduce((acc, statement) => {
            if (statement.type === 'address') {
                return {
                    ...acc,
                    [`${statement.name}_rawAddress`]: '',
                    [`${statement.name}_postcode`]: '',
                    [`${statement.name}_buildingName`]: '',
                    [`${statement.name}_buildingNumber`]: '',
                    [`${statement.name}_line1`]: '',
                    [`${statement.name}_line2`]: '',
                    [`${statement.name}_line3`]: '',
                    [`${statement.name}_line4`]: '',
                    [`${statement.name}_countryId`]: '',
                    [`${statement.name}_type`]: '',
                    [`${statement.name}_department`]: '',
                }
            }

            if (statement.type === 'calendar-slot-picker') {
                return {
                    ...acc,
                    [statement.name]: statement.defaultValue !== undefined ?
                        statement.defaultValue :
                        getDefaultValueFallback(statement.type),
                    [statement.customTimeslotFieldName]: '',
                    [statement.preferredTimeslotVisibleName]: false,
                };
            }
            return {
                ...acc,
                [statement.name]: statement.defaultValue !== undefined ?
                    statement.defaultValue :
                    getDefaultValueFallback(statement.type),
            };
        }, {});
};

export default getInitialValues;
