import React from 'react';
import { Field, useField, useFormikContext } from "formik"

const FormikHidden = ({ statement, extraState }) => {
    const formik = useFormikContext();
    const [field, meta, helpers] = useField(statement.name);
    const statementValueRef = React.useRef(statement.value);

    const valueFunc = statementValueRef.current;

    const value = valueFunc(formik.values, extraState);

    React.useEffect(() => {
        formik.setFieldValue(statement.name, value);
    }, [
        value,
    ]);

    return <Field name={statement.name} type="hidden" />;
};

export default FormikHidden;
