import * as yup from 'yup';
import getStatementField from "./getStatementField"

const getValidationSchema = (form) => {
    let fieldsStatements = getStatementField(form.content);

    const schema = fieldsStatements
        .filter(statement => statement.isField && !!statement.validation)
        .reduce((acc, statement) => {
            let statementName;

            if (statement.type !== 'address') {
                statementName = statement.name;
            } else {
                statementName = `${statement.name}_rawAddress`;
            }
            let result = {
                ...acc,
                [statementName]: statement.validation,
            };

            if (statement.type === 'calendar-slot-picker' && statement.customTimeslotFieldValidation) {
                result = {
                    ...result,
                    [statement.customTimeslotFieldName]: statement.customTimeslotFieldValidation,
                };
            }

            return result;
        }, {});

    return yup.object(schema);
};

export default getValidationSchema;
