function Form({
   spacing = 3,
    initialExtraState = {},
   submitText = 'Submit',
}, content) {
    return {
        type: 'form',
        spacing,
        content,
        initialExtraState,
        submitText,
    };
}

export default Form;
