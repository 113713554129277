import AddressSearch from "react-loqate"
import TextField from "@mui/material/TextField"
import React from "react"
import { useField, useFormikContext } from "formik"
import StatementContext from "../StatementContext"

const FormikAddressContext = React.createContext({
    customInputValue: null,
    setCustomInputValue:  () => {},
});

const CustomInput = props => {
    const { statement } = React.useContext(StatementContext);
    const [field, meta, helpers] = useField(`${statement.name}_rawAddress`);
    const addressContext = React.useContext(FormikAddressContext);
    const errorVisible = !!(meta.touched && meta.error);

    return (
        <TextField
            // placeholder={"Start typing your address or postcode"}
            autoComplete="chrome-off"
            name={field.name}
            label="Start typing your address or postcode"
            {...props}
            value={addressContext.customInputValue}
            error={errorVisible}
            helperText={errorVisible ? meta.error : ""}
            onChange={evt => {
                addressContext.setCustomInputValue(evt.target.value);
                props.onChange(evt);
            }}
            onBlur={() => {
                helpers.setTouched(true);
            }}
        />
    );
};

const FormikAddress = ({ statement }) => {
    const [customInputValue, setCustomInputValue] = React.useState('');

    const apiKey = process.env.GATSBY_LOQATE_API_KEY || '';
    const [field, meta, helpers] = useField(statement.name);
    const form = useFormikContext();

    const handleSelect = addr => {
        let address = addr.Label.replace(/\n/g, ", ");
        form.setFieldValue(`${field.name}_rawAddress`, address);
        form.setFieldValue(`${field.name}_postcode`, addr.PostalCode);
        form.setFieldValue(`${field.name}_buildingName`, addr.BuildingName);
        form.setFieldValue(`${field.name}_buildingNumber`, addr.BuildingNumber);
        form.setFieldValue(`${field.name}_line1`, addr.Line1);
        form.setFieldValue(`${field.name}_line2`, addr.Line2);
        form.setFieldValue(`${field.name}_line3`, addr.Line3);
        form.setFieldValue(`${field.name}_line4`, addr.Line4);
        form.setFieldValue(`${field.name}_countryId`, addr.CountryIso2);
        form.setFieldValue(`${field.name}_type`, addr.Type);
        form.setFieldValue(`${field.name}_department`, addr.Department);

        setTimeout(() => {
            setCustomInputValue(address);
        }, 50);
    };

    return (
        <FormikAddressContext.Provider value={{
            customInputValue,
            setCustomInputValue,
        }}>
            <AddressSearch
                locale="en_GB"
                value={form.values[`${field.name}_rawAddress`] || ''}
                apiKey={apiKey}
                countries={["GB"]}
                components={{
                    Input: CustomInput,
                }}
                className="address-search-box"
                listItemClassname="loqate-option"
                listClassname="loqate-options"
                onSelect={handleSelect}
                inputClassname={`globalForm-textField loqate-address-search-input`}
                inline
            />
        </FormikAddressContext.Provider>
    );
};

export default FormikAddress;
