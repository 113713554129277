import React from 'react';
import FormLabel from "@mui/material/FormLabel"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { useField } from "formik"

const FormikCheckbox = ({ statement }) => {
    const [field, meta, helpers] = useField(statement.name);

    return (
        <FormGroup>
            <FormLabel htmlFor={statement.name}>
                {statement.label}
            </FormLabel>
            <FormGroup
                value={field.value}
                row={statement.row}>
                {statement.options.map(option => (
                    <FormControlLabel
                        onChange={evt => {
                            const existingValue = field.value || [];

                            const newValue = existingValue.includes(evt.target.value) ?
                                existingValue.filter(v => v !== evt.target.value) :
                                existingValue.concat(evt.target.value);

                            helpers.setValue(newValue);
                        }}
                        key={option.value}
                        value={option.value}
                        control={<Checkbox />}
                        label={option.label}
                    />
                ))}
            </FormGroup>
        </FormGroup>
    );
};

export default FormikCheckbox;
