import propertyPreferenceFields from "@myaccount/forms/Forms/propertyPreference"

// Bedroom
let bedrooms = [
    {
        1: "1+",
    },
    {
        2: "2+",
    },
    {
        3: "3+",
    },
    {
        4: "4+",
    },
    {
        5: "5+",
    },
    {
        6: "6+",
    },
]

let minPrice = {
    "sales": [
            {"": "Min Price" },
            {"150000": "£150,000" },
            {"175000": "£175,000" },
            {"200000": "£200,000" },
            {"225000": "£225,000" },
            {"250000": "£250,000" },
            {"275000": "£275,000" },
            {"300000": "£300,000" },
            {"325000": "£325,000" },
            {"350000": "£350,000" },
            {"375000": "£375,000" },
            {"400000": "£400,000" },
            {"425000": "£425,000" },
            {"450000": "£450,000" },
            {"475000": "£475,000" },
            {"500000": "£500,000" },
            {"525000": "£525,000" },
            {"550000": "£550,000" },
            {"575000": "£575,000" },
            {"600000": "£600,000" },
            {"625000": "£625,000" },
            {"650000": "£650,000" },
            {"675000": "£675,000" },
            {"700000": "£700,000" },
            {"725000": "£725,000" },
            {"750000": "£750,000" },
            {"775000": "£775,000" },
            {"800000": "£800,000" },
            {"825000": "£825,000" },
            {"850000": "£850,000" },
            {"875000": "£875,000" },
            {"900000": "£900,000" },
            {"1000000": "£1,000,000" },
            {"1100000": "£1,100,000" },
            {"1200000": "£1,200,000" },
            {"1300000": "£1,300,000" },
            {"1400000": "£1,400,000" },
            {"1500000": "£1,500,000" },
            {"1600000": "£1,600,000" },
            {"1700000": "£1,700,000" },
            {"1800000": "£1,800,000" },
            {"1900000": "£1,900,000" },
            {"2000000": "£2,000,000" },
            {"2250000": "£2,250,000" },
            {"2500000": "£2,500,000" },
            {"2750000": "£2,750,000" },
            {"3000000": "£3,000,000" },
            {"3250000": "£3,250,000" },
            {"3500000": "£3,500,000" },
            {"3750000": "£3,750,000" },
            {"4000000": "£4,000,000" },
            {"4250000": "£4,250,000" },
            {"4500000": "£4,500,000" },
            {"4750000": "£4,750,000" },
            {"5000000": "£5,000,000" }
    ],
    "lettings": [
        {"": "Min Price" },
        {"100": "£100 pcm" },
        {"200": "£200 pcm" },
        {"300": "£300 pcm" },
        {"400": "£400 pcm" },
        {"500": "£500 pcm" },
        {"750": "£750 pcm" },
        {"1000": "£1,000 pcm" },
        {"2000": "£2,000 pcm" },
        {"3000": "£3,000 pcm" },
        {"4000": "£4,000 pcm" },
        {"5000": "£5,000 pcm" },
        {"10000": "£10,000 pcm" }
    ]
}

let maxPrice = {
    "sales": [
            {"": "Max Price" },
            {"150000": "£150,000" },
            {"175000": "£175,000" },
            {"200000": "£200,000" },
            {"225000": "£225,000" },
            {"250000": "£250,000" },
            {"275000": "£275,000" },
            {"300000": "£300,000" },
            {"325000": "£325,000" },
            {"350000": "£350,000" },
            {"375000": "£375,000" },
            {"400000": "£400,000" },
            {"425000": "£425,000" },
            {"450000": "£450,000" },
            {"475000": "£475,000" },
            {"500000": "£500,000" },
            {"525000": "£525,000" },
            {"550000": "£550,000" },
            {"575000": "£575,000" },
            {"600000": "£600,000" },
            {"625000": "£625,000" },
            {"650000": "£650,000" },
            {"675000": "£675,000" },
            {"700000": "£700,000" },
            {"725000": "£725,000" },
            {"750000": "£750,000" },
            {"775000": "£775,000" },
            {"800000": "£800,000" },
            {"825000": "£825,000" },
            {"850000": "£850,000" },
            {"875000": "£875,000" },
            {"900000": "£900,000" },
            {"1000000": "£1,000,000" },
            {"1100000": "£1,100,000" },
            {"1200000": "£1,200,000" },
            {"1300000": "£1,300,000" },
            {"1400000": "£1,400,000" },
            {"1500000": "£1,500,000" },
            {"1600000": "£1,600,000" },
            {"1700000": "£1,700,000" },
            {"1800000": "£1,800,000" },
            {"1900000": "£1,900,000" },
            {"2000000": "£2,000,000" },
            {"2250000": "£2,250,000" },
            {"2500000": "£2,500,000" },
            {"2750000": "£2,750,000" },
            {"3000000": "£3,000,000" },
            {"3250000": "£3,250,000" },
            {"3500000": "£3,500,000" },
            {"3750000": "£3,750,000" },
            {"4000000": "£4,000,000" },
            {"4250000": "£4,250,000" },
            {"4500000": "£4,500,000" },
            {"4750000": "£4,750,000" },
            {"5000000": "£5,000,000" }
    ],
    "lettings": [
        {"": "Max Price" },
        {"100": "£100 pcm" },
        {"200": "£200 pcm" },
        {"300": "£300 pcm" },
        {"400": "£400 pcm" },
        {"500": "£500 pcm" },
        {"750": "£750 pcm" },
        {"1000": "£1,000 pcm" },
        {"2000": "£2,000 pcm" },
        {"3000": "£3,000 pcm" },
        {"4000": "£4,000 pcm" },
        {"5000": "£5,000 pcm" },
        {"10000": "£10,000 pcm" }
    ]
}

let buildings = {
    "residential": [
        { "": "All Properties" },
        { "apartment": "Apartment" },
        { "house": "House" },
        { "bungalow": "Bungalow" },
        { "development plot": "Development Plot"},
        { "maisonette": "Maisonette"},
        { "land": "Land" }
    ],
    "commercial": [
        { "": "All Properties" },
        { "shop": "Shop" },
        { "food-premises": "Food Premises" },
        { "office": "Office" },
        { "industrial": "Industrial/Storage" },
        { "upperpart": "Shop & Upper Part" },
        { "retail": "Retail Office" },
        { "land": "Land/Development" },
        { "community-premises": "Community Premises" }
    ],
    "new_developments": [
        { "": "All Properties" },
        { "bungalow": "Bungalows" },
        { "apartment": "Flat/Apartment" },
        { "house": "House" },
        { "land": "Land" },
        { "new-home": "New Home" },
        { "semi-detached": "Semi-Detached" },
        { "terrace": "Terrace" }

    ]
}

let beds = propertyPreferenceFields.filter(item => item.name === "bedroom")
if (beds.length) {
    bedrooms = beds.pop().values
}

let minPriceField = propertyPreferenceFields.filter(item => item.name === "minPrice")
if (minPriceField.length) {
    minPrice = minPriceField.pop().values
}

let maxPriceField = propertyPreferenceFields.filter(item => item.name === "maxPrice")
if (maxPriceField.length) {
    maxPrice = maxPriceField.pop().values
}

let buildingField = propertyPreferenceFields.filter(item => item.name === "building")
if (buildingField.length) {
    buildings = buildingField.pop().values
}

export { bedrooms, minPrice, maxPrice, buildings }
