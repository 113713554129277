const Typography = ({ variant = 'body2', component }, content) => {

    return {
        type: 'typography',
        content,
        variant,
        component,
    }
};

export default Typography;
