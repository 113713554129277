import React from 'react';
import { Form, Formik, useFormikContext } from "formik"
import Stack from '@mui/material/Stack';
import getInitialValues from './helpers/getInitialValues';
import isArray from 'lodash/isArray';
import Button from "@mui/material/Button";
import getValidationSchema from "./helpers/getValidationSchema";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import defaults from "./defaults"
import { withSubtheme } from "../../../StarberryComponentsMui"
import FormikMultiSelect from "./components/FormikMultiSelect"
import FormikSelect from "./components/FormikSelect"
import FormikTextField from "./components/FormikTextField"
import FormikRadio from "./components/FormikRadio"
import FormikCheckbox from "./components/FormikCheckbox"
import FormikDatePicker from "./components/FormikDatePicker"
import FormikPhoneInput from "./components/FormikPhoneInput"
import FormikAddress from "./components/FormikAddress"
import FormikDateTimePicker from "./components/FormikDateTimePicker"
import FormikCalendarSlotPicker from "./components/FormikCalendarSlotPicker"
import LinearProgress from "@mui/material/LinearProgress"
import { Box } from "@mui/system"
import FormikHidden from "./components/FormikHidden"
import StatementContext from "./StatementContext"

const GlobalConversationalFormContext = React.createContext({
    extraState: {},
    setExtraState: () => {},
});

const FieldEffects = ({ statement, children = null }) => {
    const effectsRef = React.useRef(statement.effects);
    const formik = useFormikContext();
    const { extraState, setExtraState } = React.useContext(GlobalConversationalFormContext);

    effectsRef.current.forEach((effectFactory) => {
        const effect = effectFactory(
            formik.values,
            extraState,
            (changes) => {
                setExtraState(old => ({
                    ...old,
                    ...changes,
                }))
            },
            formik.setFieldValue,
        );
        React.useEffect.apply(null, effect);
    });

    return children;
};

const GlobalConversationalForm = withSubtheme(({
    className,
    form,
    initialValues,
    onSubmit,
    defaultCountry
}) => {

    const [extraState, setExtraState] = React.useState(
        form.initialExtraState,
    );

    const renderField = (statement) => {
        const params = typeof statement.params === 'function' ?
            statement.params({
                extraState,
                setExtraState: (change) => {
                    setExtraState({
                        ...extraState,
                        ...change,
                    });
                }
            }) : statement.params || {};

        const fieldProps = {
            statement,
            extraState,
            params,
        };

        switch (statement.type) {
            case 'text':
            case 'number':
                return <FormikTextField {...fieldProps} />;
            case 'radio':
                return <FormikRadio {...fieldProps} />;
            case 'checkbox':
                return <FormikCheckbox {...fieldProps} />;
            case 'select':
                return <FormikSelect {...fieldProps} />;
            case 'multiSelect':
                return <FormikMultiSelect {...fieldProps} />;
            case 'date':
                return <FormikDatePicker {...fieldProps} />;
            case 'datetime':
                return <FormikDateTimePicker {...fieldProps} />;
            case 'address':
                return <FormikAddress {...fieldProps} />
            case 'tel':
                return <FormikPhoneInput {...fieldProps} defaultCountry={defaultCountry} />;
            case 'calendar-slot-picker':
                return <FormikCalendarSlotPicker {...fieldProps} />;
            case 'hidden':
                return <FormikHidden {...fieldProps} />;
            default:
                return null;
        }
    };

    const renderStatement = (formikForm, statement) => {
        if (isArray(statement)) {
            return (
                <React.Fragment>
                    {statement.map((stat, index) => (
                        /* the index is okey in this case since the form is an invariant */
                        <React.Fragment key={index}>
                            {renderStatement(formikForm, stat)}
                        </React.Fragment>
                    ))}
                </React.Fragment>
            );
        }

        if (statement.type === 'condition') {
            const conditionResult = statement.if(formikForm.values, extraState);

            if (typeof conditionResult !== 'boolean') {
                throw new Error(`expected condition result to be boolean ${statement.type}`);
            }

            if (conditionResult) {
                return renderStatement(formikForm, statement.statement);
            }

            return null;
        }

        if (statement.type === 'block') {
            return (
                <Stack
                    sx={{ '& > *': {flex: 1}}}
                    spacing={statement.spacing}
                    direction={statement.direction}>
                    {renderStatement(formikForm, statement.statement)}
                </Stack>
            );
        }

        if (statement.type === 'grid') {
            return (
                <Grid container spacing={statement.spacing}>
                    {renderStatement(formikForm, statement.items)}
                </Grid>
            )
        }

        if (statement.type === 'grid-item') {
            return (
                <Grid item {...statement.options}>
                    <Stack direction="column" spacing={1}>
                        {renderStatement(formikForm, statement.statement)}
                    </Stack>
                </Grid>
            );
        }

        if (statement.type === 'heading') {
            return (
                <Typography className="globalForm-fieldsTitle" variant="h5">
                    {statement.content}
                </Typography>
            );
        }

        if (statement.type === 'separator') {
            return <Divider />
        }

        if (statement.type === 'typography') {
            return (
                <Typography
                    component={statement.component}
                    variant={statement.variant}>
                    {statement.content}
                </Typography>
            );
        }

        if (statement.type === 'loading-indicator') {
            return (
                <Box sx={{width: '100%'}} my={statement.spacing}>
                    <LinearProgress />
                </Box>
            )
        }

        if (statement.type === 'effect') {
            return <FieldEffects statement={statement} />;
        }

        return (
            <StatementContext.Provider value={{statement}}>
                <FieldEffects statement={statement}>
                    {renderField(statement)}
                </FieldEffects>
            </StatementContext.Provider>
        );
    };

    const formInitialValues = React.useMemo(() => {
        const defaultInitialValues =  getInitialValues(form);

        return {
            ...defaultInitialValues,
            ...initialValues,
        };
    }, [form]);

    const validationSchema = React.useMemo(() => {
        return getValidationSchema(form);
    }, [form]);

    return (
        <GlobalConversationalFormContext.Provider value={{
            extraState,
            setExtraState,
        }}>
            <Formik
                validationSchema={validationSchema}
                initialValues={formInitialValues}
                onSubmit={onSubmit}
            >
                {(formikForm) => (
                    <Form className={className}>
                        <Stack direction="column" spacing={form.spacing}>
                            {renderStatement(formikForm, form.content)}
                            {!console.log(formikForm.errors)}
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                color="primary"
                            >
                                {form.submitText}
                            </Button>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </GlobalConversationalFormContext.Provider>
    )
}, 'globalForm', defaults);

export default GlobalConversationalForm;
