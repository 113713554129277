import isArray from "lodash/isArray"
import flatten from "lodash/flatten"
import compact from 'lodash/compact';

const getStatementField = (statement) => {
    if (isArray(statement)) {
        return compact(flatten(statement.map(stm => getStatementField(stm))));
    }

    if (statement.type === 'grid') {
        return compact([].concat(getStatementField(statement.items)));
    }

    if (statement.type === 'condition' || statement.type === 'block' || statement.type === 'grid-item') {
        return compact([].concat(getStatementField(statement.statement)));
    }

    if (statement.isField) {
        return statement;
    }

    if (['heading', 'loading-indicator', 'typography', 'separator', 'effect'].includes(statement.type)) {
        return null;
    }

    throw new Error(`unknown statement ${statement.type}`);
}

export default getStatementField;
