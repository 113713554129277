import React from 'react';
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { useField, useFormikContext } from "formik"

const FormikSelect = ({ statement }) => {
    const form = useFormikContext();
    const [field, meta, helpers] = useField(statement.name);
    const errorVisible = !!(meta.touched && meta.error);

    const [options, setOptions] = React.useState(typeof statement.options === 'function' ?
    statement.options(form.values) : statement.options || []);

    React.useEffect(() => {
        const getOptions = async () => {
            const dataOptions = await statement.getOptions()
            console.log("dataOptions => sdfsdffdgfg", dataOptions)
            setOptions(dataOptions);
        }

        if (statement.getOptions) {
            getOptions()
        }
    }, [])

    const renderOptions = (options) => {
        return options.map(item => {
            if (Object.keys(item).length === 1) {
                let optionValue = item
                let optionLabel = item
                Object.keys(item).forEach(key => {
                    optionValue = key
                    optionLabel = item[key]
                })
                return (
                    <MenuItem
                        key={optionValue}
                        value={optionValue}>
                        {optionLabel}
                    </MenuItem>
                )
            } else {
                return (
                    <MenuItem
                        key={item.value}
                        value={item.value}>
                        {item.label}
                    </MenuItem>
                )
            }
        })
    }
    return (
        <FormControl
            error={errorVisible}
            className="globalForm-selectField-wrap"
        >
            {statement.label && (
                <InputLabel id={`select-label-${field.name}`}>
                    {statement.label}
                </InputLabel>
            )}
            <Select
                error={errorVisible}
                name={field.name}
                value={field.value}
                labelId={`select-label-${field.name}`}
                id={`select-field-${field.name}`}
                label={statement.label}
                onChange={evt => {
                    helpers.setValue(evt.target.value);
                }}
            >
                {renderOptions(options)}
            </Select>
            <FormHelperText>{errorVisible ? meta.error : ""}</FormHelperText>
        </FormControl>
    );
};

export default FormikSelect;
