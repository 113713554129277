import React from 'react';
import InputLabel from "@mui/material/InputLabel"
import { useField, useFormikContext } from "formik"
import Select2 from "react-select"
import FormGroup from "@mui/material/FormGroup"

const FormikMultiSelect = ({ statement }) => {
    const [field, meta, helpers] = useField(statement.name);
    const form = useFormikContext();

    const [options, setOptions] = React.useState(statement.options);


    React.useEffect(() => {
        const getOptions = async () => {
            const dataOptions = await statement.getOptions()
            setOptions(dataOptions);
        }

        if (statement.getOptions) {
            getOptions()
        }
    }, [])

    return (
        <FormGroup>
            {statement.label && (
                <InputLabel htmlFor={statement.name} className="multi-select-input-label">
                    {statement.label}
                </InputLabel>
            )}
            {!statement.async ? (
                <Select2
                    value={field.value}
                    className="multi-select-input"
                    classNamePrefix="multi-select"
                    options={options}
                    isMulti
                    onChange={(value) => {
                        helpers.setValue(value);
                    }}
                    defaultValue={statement.defaultValue}
                    placeholder={statement.placeholder}
                    {...statement.params}
                />
            ) : (
                <Select2
                    value={field.value}
                    className="multi-select-input"
                    classNamePrefix="multi-select"
                    defaultOptions
                    options={options}
                    isMulti
                    onChange={(value) => {
                        form.setFieldValue(field.name, value);
                    }}
                    defaultValue={statement.defaultValue}
                    placeholder={statement.placeholder}
                    {...statement.params}
                />
            )}
        </FormGroup>
    );
};

export default FormikMultiSelect;
